import { useAtom } from "jotai"
import React, { useEffect, useRef, useState } from "react"

import Loading from "components/loading"
import InputField from "components/input-field"
import ReactTextareaAutosize from "react-textarea-autosize"
import ExclamationIcon from "icons/ExclamationIcon"
import _ from "lodash"
import ModelIcon from "icons/ModelIcon"
import CloseIcon from "icons/CloseIcon"
import { useLocation } from "react-router-dom"
import FactsSelect from "./subcomponents/FactsSelect"
import { options } from "./subcomponents/FactsSelect/options"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import FileDropzone from "./subcomponents/FileDropzone"
import { legalDocumentTypeAtom } from "pages/SidebarPages/subcomponents/atoms"
import useLegalDocumentTemplatesAPI from "hooks/useLegalDocumentTemplatesAPI"
import { userInputAtom } from "pages/DocumentPage/atoms"

function DocumentFacts({ documentId }: { documentId: string }) {
	const location = useLocation()

	const [userInput, setUserInput] = useAtom(userInputAtom)
	const [isDocumentAsModel, setIsDocumentAsModel] = useState(
		location.state ? location.state.documentAsModel : false,
	)
	const [legalDocumentType, setLegalDocumentType] = useAtom(
		legalDocumentTypeAtom,
	)
	const [placeHolder, setPlaceHolder] = useState(options[0].description)

	const { updateLegalDocument, retrieveLegalDocument } = useLegalDocumentAPI()
	const { getLegalDocumentTemplateById } = useLegalDocumentTemplatesAPI()

	const textAreaRef = useRef<HTMLTextAreaElement>(null)
	const isInitialMount = useRef(true)
	const queryClient = useQueryClient()

	const { data, isFetched } = useQuery(
		["legalDocument", documentId],
		() => retrieveLegalDocument(documentId),
		{
			staleTime: Infinity,
		},
	)

	const { data: modelTemplate, isLoading: templateIsLoading } = useQuery(
		["template", data?.template_id],
		() => getLegalDocumentTemplateById(data?.template_id || ""),
		{
			refetchOnWindowFocus: false,
			enabled: !!data?.template_id && data.template_type === "TEMPLATE",
		},
	)

	const { data: modelDocument, isLoading: modelDocumentIsLoading } = useQuery(
		["legalDocument", data?.template_id],
		() => retrieveLegalDocument(data?.template_id || ""),
		{
			refetchOnWindowFocus: false,
			enabled: !!data?.template_id && data.template_type === "DOCUMENT",
		},
	)

	const updateDocumentMutation = useMutation({
		mutationFn: updateLegalDocument,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["legalDocument", documentId],
			})
		},
	})

	const isTempleteModel = data?.template_type === "TEMPLATE"
	const isDocumentModel = data?.template_type === "DOCUMENT"

	const debouncedOnChange = _.debounce((value, typeInput) => {
		const payload = {
			user_input: value,
			legal_document_type: typeInput,
		}
		updateDocumentMutation.mutate({
			id: documentId,
			payload,
		})
	}, 500)

	const getErrorText = () => {
		if (userInput && userInput.length > 10000) {
			return "Você ultrapassou o limite de caracteres"
		}

		return "Escreva pelo menos 20 caracteres"
	}

	function characterCountText() {
		const limit = 10000
		if (!userInput.length) {
			return `${limit.toLocaleString("pt-BR")} caracteres restantes`
		} else if (userInput.length > limit) {
			return `${(userInput.length - limit).toLocaleString(
				"pt-BR",
			)} caracteres excedentes`
		} else {
			return `${(limit - userInput.length).toLocaleString(
				"pt-BR",
			)} caracteres restantes`
		}
	}

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false
		} else {
			debouncedOnChange(userInput, legalDocumentType)
		}

		return () => {
			debouncedOnChange.cancel()
		}
	}, [userInput, legalDocumentType])

	useEffect(() => {
		if (isFetched) {
			const currentPlaceHolder = options.find(
				(option) => option.title === data?.legal_document_type,
			)?.description
			setPlaceHolder(
				currentPlaceHolder ||
					"O autor sofreu um acidente de trabalho no dia tal e teve o benefício do INSS negado, necessitando entrar com uma ação judicial para obter o benefício devido.",
			)
			if (data?.template_type) {
				setIsDocumentAsModel(true)
			}
		}
	}, [isFetched])

	useEffect(() => {
		if (textAreaRef.current) {
			textAreaRef.current.focus()
		}
	}, [textAreaRef.current])

	const isValidUserInput =
		(userInput && userInput.length >= 20 && userInput.length <= 10000) ||
		userInput === ""

	if (
		documentId !== data?.id ||
		!legalDocumentType ||
		!isFetched ||
		(isTempleteModel && templateIsLoading) ||
		(isDocumentModel && modelDocumentIsLoading)
	)
		return <Loading />

	return (
		<>
			<div className="w-full">
				<div
					className={` w-[420px] ${
						isDocumentAsModel ? "mb-2" : "mb-6"
					}`}
				>
					<p className="text-brand-gray-3">Tipo de documento:</p>
					{isDocumentAsModel ? (
						<InputField
							className={`border-1 mt-2 border border-solid border-light-blue-1 p-2 shadow-outlined rounded w-full max-w-[420px]  bg-[#F2F6FB] text-light-blue-1 text-ellipsis overflow-hidden h-12`}
							readOnly
							value={
								modelTemplate?.title ||
								modelDocument?.title ||
								data.legal_document_type
							}
							leftIcon={
								<div className="mt-2">
									<ModelIcon
										className={"fill-light-blue-1"}
									/>
								</div>
							}
							rightIcon={
								isDocumentAsModel && (
									<div className="pt-[10px]">
										{" "}
										<div
											className="cursor-pointer"
											onClick={() => {
												updateDocumentMutation.mutate({
													id: documentId,
													payload: {
														template_type: null,
														template_id: null,
													},
												})
												setIsDocumentAsModel(false)
											}}
										>
											<CloseIcon />
										</div>
									</div>
								)
							}
							name="specificType"
							onChange={(e) =>
								setLegalDocumentType(e.target.value)
							}
						/>
					) : (
						<FactsSelect
							setPlaceHolder={setPlaceHolder}
							legalDocumentType={legalDocumentType}
							setLegalDocumentType={setLegalDocumentType}
						/>
					)}
				</div>
				{isDocumentAsModel && (
					<div className="mb-6 text-brand-gray-2 text-[14px]">
						Seu documento será criado a partir deste modelo.
					</div>
				)}
				<div className="mb-5 w-full">
					<p className="text-brand-gray-3">
						Descreva todas as informações que são relevantes para o
						seu documento:
					</p>
					<ReactTextareaAutosize
						id="textarea"
						className={`placeholder:text-[#ACACAC] border-none resize-none !outline-offset-0 border-1 mb-1 mt-2 min-h-[156px] w-full overflow-hidden rounded-md border border-gray-100 bg-white p-4 shadow-outlined focus:outline-light-blue-4 focus:outline focus:outline-1 ${
							!isValidUserInput
								? "!outline-red-500 !outline !outline-1"
								: ""
						}`}
						placeholder={placeHolder}
						lang="pt-br"
						name="userInput"
						value={userInput || ""}
						ref={textAreaRef}
						onChange={(e) => setUserInput(e.target.value)}
						data-cy="user-input-textarea"
					/>
					<div className="flex items-center justify-between w-full">
						{!isValidUserInput ? (
							<div className="flex items-center">
								<ExclamationIcon className="mr-2 text-light-red" />
								<p className="text-light-red text-cta-2">
									{getErrorText()}
								</p>
							</div>
						) : (
							<div />
						)}

						<div className="mr-[0.5px]">
							<p
								className={`${
									!isValidUserInput
										? "text-light-red"
										: "text-brand-gray-2"
								} text-cta-2`}
							>
								{characterCountText()}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="mb-2">
				<p className="text-brand-gray-3">
					Anexe peças, contratos ou outros documentos aos fatos:{" "}
					<span className="text-[#B0B0B0]">(opcional)</span>
				</p>
			</div>
			<FileDropzone documentId={documentId} data={data} />
		</>
	)
}

export default DocumentFacts
