import InputField from "components/input-field"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import FitArrowIcon from "icons/FitArrowIcon"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import { useQuery } from "@tanstack/react-query"
import { useLocation } from "react-router-dom"
import { removeSpecialCharacters } from "utils/format"

interface FactsSelectProps {
	setPlaceHolder: (value: string) => void
	legalDocumentType: string
	setLegalDocumentType: (value: string) => void
}

export default function FactsSelect({
	setPlaceHolder,
	legalDocumentType,
	setLegalDocumentType,
}: FactsSelectProps) {
	const location = useLocation()
	const isOpenSelect: boolean = location?.state?.isOpenSelect || false

	const [openOptions, setOpenOptions] = useState(false)
	const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
	const [value, setValue] = useState(legalDocumentType || "Petição Inicial")
	const optionsRef = useRef<HTMLDivElement>(null)
	const inputRef = useRef<HTMLInputElement>(null)
	const { legalDocumentPropmts } = useLegalDocumentAPI()
	const optionRefs = useRef<(HTMLDivElement | null)[]>([])
	const { data: optionsData } = useQuery(
		["legalDocumentPrompts"],
		legalDocumentPropmts,
		{
			refetchOnWindowFocus: false,
		},
	)

	const filteredOptions = optionsData
		? optionsData?.filter((option) =>
				removeSpecialCharacters(option.document_name)
					.toLowerCase()
					.includes(removeSpecialCharacters(value.toLowerCase())),
		  )
		: []

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				optionsRef.current &&
				!optionsRef.current.contains(event.target as Node)
			) {
				setOpenOptions(false)
			}
		}

		if (openOptions) {
			document.addEventListener("mousedown", handleClickOutside)
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [openOptions])

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (!openOptions) return

			switch (event.key) {
				case "ArrowDown":
					setSelectedIndex((prevIndex) =>
						prevIndex === null ||
						prevIndex === filteredOptions.length - 1
							? 0
							: prevIndex + 1,
					)
					break
				case "ArrowUp":
					setSelectedIndex((prevIndex) =>
						prevIndex === null || prevIndex === 0
							? filteredOptions.length - 1
							: prevIndex - 1,
					)
					break
				case "Enter":
					if (selectedIndex !== null) {
						setValue(filteredOptions[selectedIndex].document_name)
						setLegalDocumentType(
							filteredOptions[selectedIndex].document_name,
						)
						setPlaceHolder(
							filteredOptions[selectedIndex].placeholder,
						)
						setOpenOptions(false)
					}
					break
				default:
					break
			}
		}

		document.addEventListener("keydown", handleKeyDown)
		return () => {
			document.removeEventListener("keydown", handleKeyDown)
		}
	}, [openOptions, filteredOptions, selectedIndex])

	useEffect(() => {
		setSelectedIndex(null)
	}, [value])

	useEffect(() => {
		if (selectedIndex !== null && optionRefs.current[selectedIndex]) {
			optionRefs.current[selectedIndex]!.scrollIntoView({
				block: "nearest",
			})
		}
	}, [selectedIndex])

	useLayoutEffect(() => {
		if (openOptions && !value && optionsData && optionsData?.length > 0) {
			const findOptionIndex = optionsData.findIndex(
				(option) => option.document_name === legalDocumentType,
			)
			const selectedElement = document.getElementById(
				`option-${findOptionIndex}`,
			)
			if (selectedElement) {
				selectedElement.scrollIntoView({ block: "nearest" })
			}
		}
	}, [openOptions, selectedIndex, value, optionsData])

	useEffect(() => {
		if (isOpenSelect) {
			setOpenOptions(true)
		}
	}, [isOpenSelect])

	useEffect(() => {
		setValue(legalDocumentType)
	}, [legalDocumentType])

	useEffect(() => {
		if (!optionsData) return
		const valuesMatchOption = optionsData.some(
			(option) =>
				option.document_name.toLowerCase() === value.toLowerCase(),
		)

		if (openOptions) {
			setValue("")
		}

		if (
			(!openOptions && filteredOptions.length === 0) ||
			!valuesMatchOption
		) {
			setValue(legalDocumentType)
		}
		inputRef.current?.focus()
	}, [openOptions])

	if (!legalDocumentType) return

	return (
		<div
			className="relative"
			onClick={() => setOpenOptions(!openOptions)}
			ref={optionsRef}
		>
			<InputField
				className={`h-[48px] w-full `}
				onChange={(e) => setValue(e.target.value)}
				value={value}
				placeholder={legalDocumentType}
				ref={inputRef}
			/>
			<div
				className={`absolute top-[28%] right-[16px] fill-current cursor-pointer ${
					openOptions && "rotate-180"
				}`}
				onClick={() => setOpenOptions(!openOptions)}
			>
				<FitArrowIcon className="fill-current" />
			</div>
			{openOptions && (
				<div className="overflow-y-auto absolute top-[56px] left-0 h-fit max-h-[160px] w-full max-w-[420px] bg-white shadow-outlined-hover rounded">
					{filteredOptions.length > 0 ? (
						<>
							{filteredOptions.map((option, index) => (
								<div
									key={option.document_name}
									className={`p-2 cursor-pointer text-cta-2 h-[32px] flex items-center text-brand-gray-3 hover:text-dark-blue-2 bg-brand-white-2 hover:bg-brand-white-3 ${
										selectedIndex === index &&
										"bg-brand-white-3 text-dark-blue-2"
									} ${
										legalDocumentType ===
											option.document_name &&
										`font-semibold bg-brand-white-3 !text-dark-blue-1`
									}`}
									ref={(el) =>
										(optionRefs.current[index] = el)
									}
									id={`option-${index}`}
									onClick={() => {
										setValue(option.document_name)
										setLegalDocumentType(
											option.document_name,
										)
										setPlaceHolder(option.placeholder)
										setOpenOptions(false)
										inputRef.current?.focus()
									}}
								>
									{option.document_name}
								</div>
							))}
						</>
					) : (
						<p className="text-brand-gray-3 p-2 text-cta-2">
							Nenhum resultado encontrado
						</p>
					)}
				</div>
			)}
		</div>
	)
}
