import PlanName from "components/plan-name"
import ArrowDownIcon from "icons/ArrowDownIcon"
import { CurrentUser } from "types/currentUser"
import { formatUserName } from "utils/profile"

const Profile = ({
	user,
	openMenu,
}: {
	user: CurrentUser
	openMenu: boolean
}) => {
	return (
		<div
			className={`${
				openMenu && "bg-ice-white"
			} w-[185px] flex h-[48px] items-center justify-between rounded px-[8px] py-[4px] hover:shadow-custom hover:shadow-[#00000024]`}
		>
			<div className="flex items-center">
				<div className={`w-[32px] mx-0`}>
					<img
						src={user?.picture}
						height={32}
						width={32}
						className="rounded-full border border-sky-500 bg-cover "
						referrerPolicy="no-referrer"
					/>
				</div>
				<nav>
					<div className={`overflow-hidden ml-2 w-[100px]`}>
						<p className="max-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap text-sm font-semibold">
							{formatUserName(user)}
						</p>
						<PlanName
							name={user.subscription?.product_name || ""}
							className="text-small-1 leading-[14px] mt-1 !font-normal"
						/>
					</div>
				</nav>
			</div>
			<div className={`${openMenu && "rotate-180"}`}>
				<ArrowDownIcon className="fill-dark-blue-1 w-[16px] h-[16px]" />
			</div>
		</div>
	)
}

export default Profile
