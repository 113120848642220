import { useEffect } from "react"
import { OnboardingStepProps } from "pages/MyDocumentsPage/subcomponents/ModalOnboarding"
import { CardSelector } from "./CardSelector"
import ManImage from "assets/man.jpg"
import WomanImage from "assets/woman.jpg"
import OfficeImage from "assets/office.jpg"
import EllipseFooter from "./EllipseFooter"
import { UserType } from "types/currentUser/enum"

export function SecondStep({
	currentStep,
	formData,
	setFormData,
	onStepChanges,

	setSize,
}: OnboardingStepProps) {
	useEffect(() => {
		setSize("752")
	}, [])

	const userType = [
		{ image: ManImage, title: "Estudante", user_type: UserType.Student },
		{
			image: WomanImage,
			title: "Autônomo(a)",
			user_type: UserType.SelfEmployed,
		},
		{
			image: OfficeImage,
			title: "Escritório",
			user_type: UserType.LawOffice,
		},
	]
	return (
		<div className="flex justify-center flex-col p-[24px] w-full">
			<div className="flex items-center mb-8 text-[22px] font-lato font-semibold">
				Em que contexto você está atualmente na área do Direito?
			</div>
			<div className="flex mb-8 gap-4">
				{userType.map(({ image, title, user_type }) => {
					return (
						<CardSelector
							key={user_type}
							image={image}
							title={title}
							onClick={() => {
								setFormData({
									...formData,
									user_type,
								})
								onStepChanges(currentStep + 1)
							}}
						/>
					)
				})}
			</div>
			<EllipseFooter activeIndex={0} />
		</div>
	)
}
